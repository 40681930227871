import React from 'react';
import { useNavigate } from 'react-router-dom';
// Constants
import { SiteTitle, TROUBLESHOOT_PAGE_5 } from '../../utils/Constants';


function LibrarySnapShotInstructions() {
  let navigate = useNavigate();

  const continueToNextPage = () => {
    navigate(TROUBLESHOOT_PAGE_5, { replace: false });
  };

  return (
    <section className='grid bg-white shadow px-6 py-6'>
      <div className='grid gap-12 w-full h-full'>
        <article className='grid gap-4 text-center'>
          <h1 className='font-medium'>{SiteTitle}</h1>
        </article>

        <section className='grid'>
          <div className='mb-4 w-[250px] mx-auto text-center'>
            <p className='block text-gray-700 text-sm font-bold mb-2'>
              Instruction to use the LIBRARY SNAPSHOT feature to recover the
              account.
            </p>
          </div>

          <div className='grid justify-end'>
            <button
              type='submit'
              onClick={continueToNextPage}
              className='bg-main-colour hover:brightness-90 text-white font-bold py-2 px-4 rounded active:scale-95'
            >
              Continue
            </button>
          </div>
        </section>
      </div>
    </section>
  );
}

export default LibrarySnapShotInstructions;
