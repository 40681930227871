import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// Constants
import {
  CREATE_RECORD_API,
  SiteTitle,
  TROUBLESHOOT_PAGE_0A,
  TROUBLESHOOT_PAGE_1,
} from '../../utils/Constants';
// Context
import { UserContext } from '../../context/UserContext';
// Api
import client from '../../api/client';

function CreateRequestForm() {
  const { userData, setUserData } = useContext(UserContext);

  const [userEmail, setUserEmail] = useState('');
  const [emailInUse, setEmailInUse] = useState(false);
  let navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmailInUse(false);
    setUserEmail(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();

    setUserData({
      ...userData,
      email: userEmail,
      page: 1,
    });

    let user = {
      email: userEmail,
      page: 1,
    };

    client
      .post(CREATE_RECORD_API, user, false)
      .then((res) => {
        setUserData({
          ...userData,
          userId: res.data.data.newUserRecord.id,
        });
        navigate(TROUBLESHOOT_PAGE_1, { replace: false });
      })

      .catch((err) => {
        console.error('Unable to create user recored', err);
        if (err.response.data.data.message === 'Email already in use') {
          setEmailInUse(true);
        }
      });
  };

  return (
    <section className='grid bg-white shadow px-6 py-6'>
      <div className='grid gap-12 w-full h-full'>
        <article className='grid gap-4'>
          <h1 className='font-medium'>{SiteTitle}</h1>
          <h2 className='max-w-[250px] mx-auto text-center'>
            Enter the email address associated with your
            www.silhouettedesignstore.com account to begin troubleshooting.
          </h2>
        </article>

        <section className='grid gap-8'>
          <div className='text-center'>
            <Link
              to={TROUBLESHOOT_PAGE_0A}
              className='text-hyperlink text-center hover:brightness-75'
            >
              Click here to resume or view the <br /> status of a previous
              troubleshooting session.
            </Link>
          </div>

          {emailInUse && (
            <div className='text-center'>
              <span className='text-red-600'>Email already in use!</span>
            </div>
          )}

          <form onSubmit={submitForm}>
            <div className='mb-4'>
              <label
                htmlFor='email'
                className='block text-sm font-medium text-gray-700'
              >
                Email
              </label>
              <input
                type='email'
                id='email'
                name='email'
                value={userEmail}
                onChange={handleEmailChange}
                className='mt-1 block w-full p-2 border border-gray-300 rounded-md shadow sm:text-sm'
                required
              />
            </div>

            <div className='grid justify-end'>
              <button
                type='submit'
                className='bg-main-colour hover:brightness-90 text-white font-bold py-2 px-4 rounded active:scale-95'
              >
                Submit
              </button>
            </div>
          </form>
        </section>
      </div>
    </section>
  );
}

export default CreateRequestForm;
