import { Route, Routes } from 'react-router-dom';
// Constants
import {
  TROUBLESHOOT_PAGE_0,
  TROUBLESHOOT_PAGE_0A,
  TROUBLESHOOT_PAGE_1,
  TROUBLESHOOT_PAGE_2,
  TROUBLESHOOT_PAGE_3,
  TROUBLESHOOT_PAGE_4,
  TROUBLESHOOT_PAGE_5,
  TROUBLESHOOT_PAGE_6,
  TROUBLESHOOT_PAGE_7,
  TROUBLESHOOT_PAGE_8,
  TROUBLESHOOT_PAGE_9,
} from './utils/Constants';
// Pages
import TroubleshootPage0 from './pages/troubleshoot/TroubleshootPage0';
import TroubleshootPage0A from './pages/troubleshoot/TroubleshootPage0A';
import TroubleshootPage1 from './pages/troubleshoot/TroubleshootPage1';
import TroubleshootPage2 from './pages/troubleshoot/TroubleshootPage2';
import TroubleshootPage3 from './pages/troubleshoot/TroubleshootPage3';
import TroubleshootPage4 from './pages/troubleshoot/TroubleshootPage4';
import TroubleshootPage9 from './pages/troubleshoot/TroubleshootPage9';
import TroubleshootPage5 from './pages/troubleshoot/TroubleshootPage5';
import TroubleshootPage6 from './pages/troubleshoot/TroubleshootPage6';
import TroubleshootPage7 from './pages/troubleshoot/TroubleshootPage7';
import TroubleshootPage8 from './pages/troubleshoot/TroubleshootPage8';

function App() {
  return (
    <Routes>
      <Route path={TROUBLESHOOT_PAGE_0} index element={<TroubleshootPage0 />} />
      <Route path={TROUBLESHOOT_PAGE_0A} element={<TroubleshootPage0A />} />
      <Route path={TROUBLESHOOT_PAGE_1} element={<TroubleshootPage1 />} />
      <Route path={TROUBLESHOOT_PAGE_2} element={<TroubleshootPage2 />} />
      <Route path={TROUBLESHOOT_PAGE_3} element={<TroubleshootPage3 />} />
      <Route path={TROUBLESHOOT_PAGE_4} element={<TroubleshootPage4 />} />
      <Route path={TROUBLESHOOT_PAGE_5} element={<TroubleshootPage5 />} />
      <Route path={TROUBLESHOOT_PAGE_6} element={<TroubleshootPage6 />} />
      <Route path={TROUBLESHOOT_PAGE_7} element={<TroubleshootPage7 />} />
      <Route path={TROUBLESHOOT_PAGE_8} element={<TroubleshootPage8 />} />
      <Route path={TROUBLESHOOT_PAGE_9} element={<TroubleshootPage9 />} />
    </Routes>
  );
}

export default App;
