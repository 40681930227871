import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Constants
import {
  GET_RECORD_API,
  SiteTitle,
  TROUBLESHOOT_PAGE_0,
} from '../../utils/Constants';
// Context
import { UserContext } from '../../context/UserContext';
// Api
import client from '../../api/client';

function ConnectToSessionForm() {
  const { userData, setUserData, foundData, setFoundData } =
    useContext(UserContext);

  const [userEmail, setUserEmail] = useState('');
  const [emailNotFound, setEmailNotFound] = useState('');

  let navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmailNotFound(false);
    setUserEmail(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();

    setUserData({
      ...userData,
      email: userEmail,
    });

    client
      .get(`${GET_RECORD_API}/${userEmail}`)
      .then((res) => {
        setFoundData(res.data.data.foundRecord);
        let data = res.data.data.foundRecord;

        setUserData({
          ...userData,
          userId: data.id,
          email: data.email,
          latest_ver: data.latest_ver,
          resolution: data.dataolution,
          num_files_before: data.num_files_before,
          deletion_date: data.deletion_date,
          page: data.page,
          progress: data.progress,
        });
      })

      .catch((err) => {
        console.error('Unable to get user recored', err);
        setEmailNotFound(true);
      });
  };

  return (
    <section className='grid bg-white w-fit shadow px-2 md:px-6 py-6'>
      {foundData ? (
        <div className='grid gap-12 w-full h-full'>
          <article className='grid gap-4 max-w-[300px] md:max-w-[400px] text-center'>
            <h1 className='font-medium'>{SiteTitle}</h1>
            <h2 className='text-center overflow-x-clip'>
              Here is the progress report for {foundData.email}.
            </h2>

            {foundData.progress === 1 ? (
              <div className='text-center'>
                <p>
                  Your account status is <br />{' '}
                  <span className='text-green-600 font-medium'>
                    In Progress
                  </span>{' '}
                  <br />
                  Please check again soon for an update.
                </p>
              </div>
            ) : foundData.progress === 2 ? (
              <div className='text-center'>
                <p>
                  Your account status is <br />{' '}
                  <span className='text-red-600 font-medium'>Failed</span>{' '}
                  <br />
                  We have failed to recover you lost items. Please contact our
                  support email.
                </p>
              </div>
            ) : foundData.progress === 3 ? (
              <div className='text-center'>
                <p>
                  Your account status is <br />{' '}
                  <span className='text-green-600 font-medium'>Success</span>{' '}
                  <br />
                  We have successfully recovered your items. Please check your
                  Silhouette account and sync with our database to check your
                  returned items.
                </p>
              </div>
            ) : null}
          </article>
        </div>
      ) : (
        <div className='grid gap-12 w-full h-full'>
          <article className='grid gap-4'>
            <h1 className='font-medium'>{SiteTitle}</h1>
            <h2 className='max-w-[250px] mx-auto text-center'>
              Enter the email address associated with your
              www.silhouettedesignstore.com account to resume or view the status
              of a previous troubleshooting session.
            </h2>
          </article>

          <section className='grid'>
            {emailNotFound && (
              <div className='text-center'>
                <span className='text-red-600'>Email Not Found!</span>
              </div>
            )}

            <form onSubmit={submitForm}>
              <div className='mb-4'>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium text-gray-700'
                >
                  Email
                </label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  value={userEmail}
                  onChange={handleEmailChange}
                  className='mt-1 block w-full p-2 border border-gray-300 rounded-md shadow sm:text-sm'
                  required
                />
              </div>

              <div className='grid justify-end'>
                <button
                  type='submit'
                  className='bg-main-colour hover:brightness-90 text-white font-bold py-2 px-4 rounded active:scale-95'
                >
                  Submit
                </button>
              </div>
            </form>
          </section>
        </div>
      )}
    </section>
  );
}

export default ConnectToSessionForm;
