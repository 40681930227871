import React, { useState } from 'react';
// Data
import { userModel } from '../utils/TempData';

export const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(userModel);
  const [foundData, setFoundData] = useState(null);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        foundData,
        setFoundData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
