import React from 'react';
// Constants
import { SiteTitle, TROUBLESHOOT_PAGE_0A } from '../../utils/Constants';
import { Link } from 'react-router-dom';

function CompletedOperationContainer() {
  return (
    <section className='grid bg-white shadow px-6 py-6'>
      <div className='grid gap-12 w-full h-full'>
        <article className='grid gap-4 max-w-[250px] md:max-w-[350px] text-center'>
          <h1 className='font-medium'>{SiteTitle}</h1>
          <h2 className=' mx-auto text-center'>
            Thank you for submitting your request.
          </h2>
          <h3 className='mx-auto text-center'>
            Cloud Library Recovery Process in progress.
          </h3>
          <h4>
            Please allow up to 2 working days for the recovery process to take
            effect.
          </h4>
          <p>
            If you have any further issues or your account does not update after
            2 days. Please contact Silhouette America tech support via the link
            below.{' '}
          </p>

          <a
            href='https://silhouetteamerica.freshdesk.com/support/home'
            target='_blank'
            rel='noreferrer'
            className='text-hyperlink hover:brightness-75'
          >
            Silhouette America Tech Support
          </a>

          <div className='text-center'>
            <Link
              to={TROUBLESHOOT_PAGE_0A}
              className='text-hyperlink text-center hover:brightness-75'
            >
              Click here to check your request status.
            </Link>
          </div>
        </article>
      </div>
    </section>
  );
}

export default CompletedOperationContainer;
