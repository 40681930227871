import React from 'react';
// Constants
import { SiteTitle } from '../../utils/Constants';

function ThankYouContainer() {
  return (
    <section className='grid bg-white shadow px-6 py-6'>
      <div className='grid gap-12 w-full h-full'>
        <article className='grid gap-4'>
          <h1 className='font-medium'>{SiteTitle}</h1>
          <h2 className='max-w-[250px] mx-auto text-center'>
            Thank you for submitting your request.
          </h2>
          <h3 className='max-w-[250px] mx-auto text-center'>
            Instruction for feedback or further assistance with a URL to
            Silhouette America tech support
          </h3>

          <div className='text-center'>
            <a
              href='https://www.silhouettedesignstore.com'
              target='_blank'
              rel='noreferrer'
              className='text-hyperlink text-center hover:brightness-75'
            >
              Silhouette America tech support
            </a>
          </div>
        </article>
      </div>
    </section>
  );
}

export default ThankYouContainer;
