import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Constants
import {
  SiteTitle,
  TROUBLESHOOT_PAGE_2,
  TROUBLESHOOT_PAGE_4,
  UPDATE_VERSION_API,
  UPDATE_VP_API,
} from '../../utils/Constants';
// Context
import { UserContext } from '../../context/UserContext';
import client from '../../api/client';

function StudioVersionForm() {
  const { userData, setUserData } = useContext(UserContext);
  const [versionSelected, setVersionSelected] = useState('');
  let navigate = useNavigate();

  const handleVersionChange = (e) => {
    setVersionSelected(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();

    setUserData({
      ...userData,
      latest_ver: versionSelected === 'yes' ? 'yes' : 'no',
      page: versionSelected === 'yes' ? 4 : 2,
    });

    const nextPage =
      versionSelected === 'yes' ? TROUBLESHOOT_PAGE_4 : TROUBLESHOOT_PAGE_2;

    const updateData = {
      page: versionSelected === 'yes' ? 4 : 2,
      latest_ver: versionSelected === 'yes' ? 'yes' : 'no',    
    }

    client
      .patch(`${UPDATE_VP_API}/${userData.userId}`, updateData, false)
      .then((res) => {
        navigate(nextPage, { replace: false });
      })

      .catch((err) => {
        console.error('Unable to create user recored', err);
      });

  };

  return (
    <section className='grid bg-white shadow px-6 py-6'>
      <div className='grid gap-12 w-full h-full'>
        <article className='grid gap-4'>
          <h1 className='font-medium'>{SiteTitle}</h1>
        </article>

        <section className='grid'>
          <form onSubmit={submitForm}>
            <div className='mb-4'>
              <p className='block text-gray-700 text-sm font-bold mb-2'>
                Are you using Silhouette Studio 4.5.808?
              </p>
              <label className='mr-4'>
                <input
                  type='radio'
                  name='version'
                  value='yes'
                  checked={versionSelected === 'yes'}
                  onChange={handleVersionChange}
                  className='mr-2 leading-tight'
                  required
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='version'
                  value='no'
                  checked={versionSelected === 'no'}
                  onChange={handleVersionChange}
                  className='mr-2 leading-tight'
                />
                No
              </label>
            </div>

            <div className='grid justify-end'>
              <button
                type='submit'
                className='bg-main-colour hover:brightness-90 text-white font-bold py-2 px-4 rounded active:scale-95'
              >
                Continue
              </button>
            </div>
          </form>
        </section>
      </div>
    </section>
  );
}

export default StudioVersionForm;
