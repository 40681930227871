import React from 'react';
// Components
import Navbar from '../../components/nav/Navbar';
import SnapshotInfoForm from '../../components/forms/SnapshotInfoForm';

function TroubleshootPage7() {
  return (
    <div className='grid min-h-screen w-full overflow-hidden'>
      <div className='grid grid-rows-reg'>
        <Navbar />

        <main className='grid h-full w-full overflow-hidden items-center justify-center'>
          <div className='grid'>
            <SnapshotInfoForm />
         </div>
        </main>
      </div>
    </div>
  );
}

export default TroubleshootPage7;
