import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Constants
import {
  SiteTitle,
  TROUBLESHOOT_PAGE_6,
  TROUBLESHOOT_PAGE_9,
  UPDATE_RESOLUTION_API,
} from '../../utils/Constants';
// Context
import { UserContext } from '../../context/UserContext';
import client from '../../api/client';

function LibrarySnapshopEffectForm() {
  const { userData, setUserData } = useContext(UserContext);
  const [customerAnswer, setCustomerAnswer] = useState('');

  let navigate = useNavigate();

  const handleVersionChange = (e) => {
    setCustomerAnswer(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();

    setUserData({
      ...userData,
      resolution: customerAnswer === 'yes' ? 5 : 0,
    });

    const nextPage =
      customerAnswer === 'yes' ? TROUBLESHOOT_PAGE_9 : TROUBLESHOOT_PAGE_6;

    if (customerAnswer === 'yes') {

      const updateData = {
        resolution: 5
      };

      client
        .patch(`${UPDATE_RESOLUTION_API}/${userData.userId}`, updateData, false)
        .then((res) => {
          console.log('Successfully updated');
        })

        .catch((err) => {
          console.error('Unable to update user recored', err);
        });
    }

    navigate(nextPage, { replace: false });
  };

  return (
    <section className='grid bg-white shadow px-6 py-6'>
      <div className='grid gap-12 w-full h-full'>
        <article className='grid gap-4 text-center'>
          <h1 className='font-medium'>{SiteTitle}</h1>
        </article>

        <section className='grid'>
          <form onSubmit={submitForm}>
            <div className='mb-4'>
              <p className='block text-gray-700 text-sm font-bold mb-2'>
                Did the LIBRARY SNAPSHOT feature fix the issue?
              </p>
              <label className='mr-4'>
                <input
                  type='radio'
                  name='version'
                  value='yes'
                  checked={customerAnswer === 'yes'}
                  onChange={handleVersionChange}
                  className='mr-2 leading-tight'
                  required
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='version'
                  value='no'
                  checked={customerAnswer === 'no'}
                  onChange={handleVersionChange}
                  className='mr-2 leading-tight'
                />
                No
              </label>
            </div>

            <div className='grid justify-end'>
              <button
                type='submit'
                className='bg-main-colour hover:brightness-90 text-white font-bold py-2 px-4 rounded active:scale-95'
              >
                Continue
              </button>
            </div>
          </form>
        </section>
      </div>
    </section>
  );
}

export default LibrarySnapshopEffectForm;
