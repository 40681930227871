// Routes
export const TROUBLESHOOT_PAGE_0 = '/';
export const TROUBLESHOOT_PAGE_0A = '/troubleshoot-0a';
export const TROUBLESHOOT_PAGE_1 = '/troubleshoot-1';
export const TROUBLESHOOT_PAGE_2 = '/troubleshoot-2';
export const TROUBLESHOOT_PAGE_3 = '/troubleshoot-3';
export const TROUBLESHOOT_PAGE_4 = '/troubleshoot-4';
export const TROUBLESHOOT_PAGE_5 = '/troubleshoot-5';
export const TROUBLESHOOT_PAGE_6 = '/troubleshoot-6';
export const TROUBLESHOOT_PAGE_7 = '/troubleshoot-7';
export const TROUBLESHOOT_PAGE_8 = '/troubleshoot-8';
export const TROUBLESHOOT_PAGE_9 = '/troubleshoot-9';

// Names
export const SiteTitle = 'Silhouette Cloud Library Troubleshooter'

// Api
export const CREATE_RECORD_API = '/users/create-new-record'
export const GET_RECORD_API = '/users/get-user-record' // userId
export const UPDATE_VERSION_API = '/users/update-software-version' // userId
export const UPDATE_RESOLUTION_API = '/users/update-software-resolution' // userId
export const UPDATE_VP_API = '/users/update-version-and-page' // userId
export const UPDATE_MISSING_API = '/users/update-missing-data' // userId