import React from 'react';
// Components
import Navbar from '../../components/nav/Navbar';
import RecoveryInfoContainer from '../../components/forms/RecoveryInfoContainer';

function TroubleshootPage6() {
  return (
    <div className='grid min-h-screen w-full overflow-hidden'>
      <div className='grid grid-rows-reg'>
        <Navbar />

        <main className='grid h-full w-full overflow-hidden items-center justify-center'>
          <div className='grid'>
            <RecoveryInfoContainer />
         </div>
        </main>
      </div>
    </div>
  );
}

export default TroubleshootPage6;
