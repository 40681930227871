import React from 'react';
// Components
import Navbar from '../../components/nav/Navbar';
import ConnectToSessionForm from '../../components/forms/ConnectToSessionForm';

function TroubleshootPage0A() {
  return (
    <div className='grid min-h-screen w-full overflow-hidden'>
      <div className='grid grid-rows-reg'>
        <Navbar />

        <main className='grid h-full w-full overflow-hidden items-center justify-center'>
          <div className='grid'>
            <ConnectToSessionForm />
          </div>
        </main>
      </div>
    </div>
  );
}

export default TroubleshootPage0A;
