import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Constants
import {
  SiteTitle,
  TROUBLESHOOT_PAGE_8,
  UPDATE_MISSING_API,
} from '../../utils/Constants';
// Context
import { UserContext } from '../../context/UserContext';
import client from '../../api/client';

function SnapshotInfoForm() {
  const { userData, setUserData } = useContext(UserContext);
  const [numFilesBefore, setNumFilesBefore] = useState('');
  const [deletionDate, setDeletionDate] = useState('');

  let navigate = useNavigate();

  const handleNumFilesChange = (e) => {
    setNumFilesBefore(e.target.value);
  };

  const handleDateChange = (e) => {
    setDeletionDate(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();

    setUserData({
      ...userData,
      num_files_before: numFilesBefore,
      deletion_date: deletionDate,
    });

    const updateData = {
      num_files_before: numFilesBefore,
      deletion_date: deletionDate,
    };

    client
      .patch(`${UPDATE_MISSING_API}/${userData.userId}`, updateData, false)
      .then((res) => {
        console.log('Update successful');
      })

      .catch((err) => {
        console.error('Unable to update record with missing file data', err);
      });

    navigate(TROUBLESHOOT_PAGE_8, { replace: false });
  };

  return (
    <section className='grid bg-white shadow px-6 py-6'>
      <div className='grid gap-12 w-full h-full'>
        <article className='grid gap-4 text-center'>
          <h1 className='font-medium'>{SiteTitle}</h1>
        </article>

        <section className='grid'>
          <form onSubmit={submitForm}>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2'>
                Approximately how many files existed in your account prior to
                files going missing?
              </label>
              <select
                value={numFilesBefore}
                onChange={handleNumFilesChange}
                className='shadow border rounded w-full py-2 px-3 text-gray-700'
                required
              >
                <option value=''>Select an option</option>
                <option value='1 to 10'>1 to 10</option>
                <option value='10 to 100'>10 to 100</option>
                <option value='100 to 500'>100 to 500</option>
                <option value='500 to 1000'>500 to 1000</option>
                <option value='1000 to 2000'>1000 to 2000</option>
                <option value='2000 to 5000'>2000 to 5000</option>
                <option value='More than 5000'>More than 5000</option>
              </select>
            </div>

            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2'>
                Approximately what date did you notice that files had gone
                missing?
              </label>
              <input
                type='date'
                value={deletionDate}
                onChange={handleDateChange}
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                required
              />
            </div>

            <div className='grid'>
              <button
                type='submit'
                className='bg-main-colour hover:brightness-90 text-white font-bold py-2 px-4 rounded mt-4'
              >
                Submit
              </button>
            </div>
          </form>
        </section>
      </div>
    </section>
  );
}

export default SnapshotInfoForm;
