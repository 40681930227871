import React from 'react';
import { useNavigate } from 'react-router-dom';
// Constants
import { SiteTitle, TROUBLESHOOT_PAGE_3 } from '../../utils/Constants';
// Files
import WindowsDownload from '../../assets/files/SS_V4.5.807.001_W6R.exe';
import MacDownload from '../../assets/files/SS_V4.5.807.001_M6R.dmg';

function InstallUpdateForm() {
  let navigate = useNavigate();

  const continueToNextPage = () => {
    navigate(TROUBLESHOOT_PAGE_3, { replace: false });
  };

  const handleDownload = (fileUrl) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileUrl.split('/').pop(); // Set the file name based on the URL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className='grid bg-white shadow px-6 py-6'>
      <div className='grid gap-12 w-full h-full'>
        <article className='grid gap-4'>
          <h1 className='font-medium'>{SiteTitle}</h1>
          <h2 className='max-w-[250px] mx-auto text-center'>
            Download and install the latest version of Silhouette Studio
          </h2>
        </article>

        <section className='grid'>
          <section className='grid gap-2 '>
            <div>
              <span className='font-medium'>Download SS 4.5.808 for:</span>
            </div>
            <div className='grid grid-cols-2 gap-2'>
              <div className=''>
                <button
                  type='button'
                  onClick={() => handleDownload(WindowsDownload)}
                  className='bg-main-colour hover:brightness-90 text-white font-bold py-2 px-4 rounded w-full active:scale-95'
                >
                  Windows
                </button>
              </div>

              <div className=''>
                <button
                  type='button'
                  onClick={() => handleDownload(MacDownload)}
                  className='bg-main-colour hover:brightness-90 text-white font-bold py-2 px-4 rounded w-full active:scale-95'
                >
                  Mac
                </button>
              </div>
            </div>
          </section>

          <div className='py-6'>
            <p className='max-w-[250px] mx-auto text-center'>
              Download, install and run Silhouette Studio. View your Cloud
              Library files, allowing some time for them to sync.
            </p>
          </div>

          <div className='grid justify-end'>
            <button
              type='submit'
              onClick={continueToNextPage}
              className='bg-main-colour hover:brightness-90 text-white font-bold py-2 px-4 rounded active:scale-95'
            >
              Continue
            </button>
          </div>
        </section>
      </div>
    </section>
  );
}

export default InstallUpdateForm;
