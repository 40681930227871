import React from 'react';
import { Link } from 'react-router-dom';
// Images
import MainLogo from '../../assets/images/logos/silhouette_web_small_logo.png';
// Constants
import { SiteTitle, TROUBLESHOOT_PAGE_0 } from '../../utils/Constants';

function navbar() {
  return (
    <nav className='grid nav_bar_gradient py-2 w-full overflow-hidden'>
      <div className='grid grid-cols-reg px-4 w-full'>
        {/* Logo */}

        <section>
          <Link to={TROUBLESHOOT_PAGE_0}>
            <img
              src={MainLogo}
              alt='Silhouette research and technology logo'
              className='w-16 h-16'
            />
          </Link>
        </section>

        {/* Text */}
        <section className='grid items-center w-full'>
          <div className='grid justify-end'>
            <span className='text-white text-sm md:text-base lg:text-lg'>
              {SiteTitle}
            </span>
          </div>
        </section>
      </div>
    </nav>
  );
}

export default navbar;
